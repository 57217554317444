import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import api from '../API/api'


const Report = () => {

    const [answers, setAnswes] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        api.allanswers().then((res) => {
            console.log("res", res);
            setAnswes(res.data.data)
        })
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <>
            <div className="container">

                {answers && answers.map((el) => (

                    <div className="row border border-success">
                        <div>
                            <h3>
                                <strong>
                                    User - {el.name}
                                </strong>
                            </h3>
                        </div>


                        <div>
                            Email - {el.email}
                        </div>
                        <Divider />
                        <br />
                        <br />
                        <br />
                        <div className='text-success'>
                            <strong>
                                <u>
                                    Assignment 1.
                                </u>
                            </strong>
                        </div>

                        <div>

                            <div dangerouslySetInnerHTML={{ __html: el.ans1 }} />

                        </div>
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <br />


                        <div className='text-success'>
                            <strong>
                                <u>
                                    Assignment 2.
                                </u>
                            </strong>
                        </div>

                        <div>
                            <div dangerouslySetInnerHTML={{ __html: el.ans2 }} />
                        </div>
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <br />

                        <div className='text-success'>
                            <strong>
                                <u>
                                    Assignment 3.
                                </u>
                            </strong>
                        </div>

                        <div>
                            <div dangerouslySetInnerHTML={{ __html: el.ans3 }} />
                        </div>

                    </div>

                ))}


            </div>
        </>
    )
}

export default Report