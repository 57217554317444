import React, { useEffect, useState } from 'react'
import Timer from '../home/timer';
import api from '../../API/api';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../API/token';

const Navbar = () => {
    const { user } = AuthUser();
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
        start_time: '',
        end_time: ''
    });

    useEffect(() => {
        getUser();
    }, []);

    const getUser = () => {
        if (user) {
            api.getUser(user.id)
                .then((res) => {
                    console.log(res);
                    setUserInfo(res.data.user)
                    
                })
                .catch((err) => {
                    console.log(err.response);
                })

        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-primary text-center p-4">
                <div className='col-12'>
                    <div className='row m-0 p-0'>
                        <div className='col-12 text-white'>
                        <h4> Online Assessment Test</h4>
                        <h6>Position - Content Writer (English)</h6>
                        </div>
                    </div>
                </div>

            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-secondary text-center sticky-top">
                <div className='col-12'>
                    <div className='row m-0 p-0'>
                        {user &&
                            <div className='col-4 text-white'>
                                User :  {user.name}
                            </div>
                        }


                        {userInfo.start_time &&
                            <div className='col-4 text-white'>
                                Start Time :  {userInfo.start_time}
                            </div>
                        }
                        {userInfo.end_time &&
                            <div className='col-4 text-white'>
                                End Time :  {user && userInfo.end_time}
                            </div>
                        }


                        {/* <div className='col-6 text-white'>
                            Time Left: <Timer />
                        </div> */}
                    </div>
                </div>

            </nav>
        </>
    )
}

export default Navbar;