import React from 'react'
import AuthUser from '../../API/token';

const Userinfo = () => {
    const { user } = AuthUser();
    return (
        <>
            <div className="card m-3 p-3">
                <div className="card-body text-center">
                    <h5 className="card-title">Careers at Zakti Digital Services</h5>
                    <p className="card-text">Our clients love us, because of the ‘PEOPLE’ Zakti invests in.
                    </p>
                </div>
                <div className="list-group list-group-flush">
                    <div className='row'>
                        <div className='col-6'>
                            <li className="list-group-item">User name: {user.name}</li>
                        </div>
                        <div className='col-6'>
                            <li className="list-group-item">User Id: zakti000123{user.id}</li>
                        </div>
                    </div>
                </div>
                <div className='row m-4 p-4'>
                    <p>
                        <strong> <span style={{ color: 'red' }}> Please read the instructions carefully. You can’t return to this page after starting the test.</span>
                        </strong>
                    </p>
                    <div className='ml-4 pl-4'>
                        <div>* The test is only valid from <span style={{ color: 'red' }}> 28th october 06:00 PM to 29th october 06:00 PM.</span></div>
                        <div>* Once you click on the <strong> ‘start test’</strong> button, you’ll be required to submit your answers <span style={{ color: 'red' }}> within 6 hours</span>.</div>
                        <div>* You can save your progress and resume your test in between the allotted 6 hours time, but you won’t be able to submit or modify answers (or reattempt the test) after exceeding the stipulated test duration.</div>
                        <div>* Please note that after clicking on the <strong> ‘FINAL SUBMIT’</strong> button, <u> you won’t be able to submit or modify answers (or reattempt the test)</u>.</div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Userinfo;