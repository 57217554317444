import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import api from "../../API/api";
import Cat from "./cat";
import Userinfo from "./userInfo";
import AuthUser from "../../API/token";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Question1 = () => {
  const { user } = AuthUser();
  const navigate = useNavigate();

  const handlenext = () => {
    handleSubmit();

    navigate("/q3");
    window.location.reload(false);
  };
  const handleback = () => {
    navigate(-1);
  };

  const [ans1, setAns1] = useState();
  const [userid, setUser] = useState(user.id);
  const [question, setQuestion] = useState(1);
  const [j1, setJ1] = useState("");

  useEffect(() => {
    getAnswer();
    if (user.expire == 1) {
      console.log("expire path");
      navigate("/success");
    }
    var now = Date().slice(16, 24);
    if (user.end_time <= now) {
      console.log("expire path", user.end_time);
      navigate("/success");
    }
  }, []);

  const getAnswer = () => {
    api
      .getAnswer(userid, question)
      .then((res) => {
        console.log(res.data.data.ans1);
        setAns1(res.data.data.ans1);
        setJ1(res.data.data.ans1 + 1);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handlequestion1 = (e) => {
    setAns1(e);
    console.log(e);
  };

  const handleSubmit = () => {
    api
      .ans(ans1, userid, question)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Saved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const editor = useRef(null);
  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 620,
    triggerChangeEvent: true,
    readonly: false,
    buttons: [
      "bold",
      "brush",
      "paragraph",
      "|",
      "align",
      "|",
      "undo",
      "redo",
      "|",
      "eraser",
      "dots",
    ],

    events: {
      processPaste: function(event, html) {
        console.log("oo");
        // setAns1('');
        return false;
      },

      afterPaste: function(event) {
        setAns1("");
        return false;
      },
    },
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
  };

  const Jodit1 = () => {
    return useMemo(
      () => (
        <JoditEditor
          ref={editor}
          config={config}
          value={ans1}
          onChange={handlequestion1}
        />
      ),
      [j1]
    );
  };

  const question1 = () => {
    return (
      <>
        {/* <Userinfo/> */}
        <div className="row">
          <div className="col-md-12">
            <div>
              <p>
                <strong>
                  {" "}
                  <u> Assignment 1</u>{" "}
                </strong>
                <br />
                Write three social media posts (Twitter, Instagram, and
                LinkedIn) promoting an App with an extensive collection of
                curated mock tests for popular government exams.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  {" "}
                  <u> Instructions:</u>{" "}
                </strong>
                <br />
                Each post should reflect the tone of the platform, use
                appropriate hashtags, and have a strong call-to-action. Keep the
                Twitter post under 280 characters, while the Instagram and
                LinkedIn posts can be a bit longer but still engaging.
              </p>
            </div>

            <br />
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            {Jodit1()}

            <div className="row mb-4 pb-4">
              <div className="text-end">
                <button className="btn btn-info m-2" onClick={handleSubmit}>
                  Save Progress
                </button>
                {/* <button onClick={handleback} className="btn btn-warning m-2">Back</button> */}
                <button onClick={handlenext} className="btn btn-success m-2">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <Cat question={question} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <ToastContainer />

        {question1()}
      </div>
    </>
  );
};

export default Question1;
