import React, {useEffect} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AuthUser from '../../API/token';

function Success() {
    const { logout } = AuthUser();

    useEffect(()=>{
        logout()
    }, []);

    return (
        <div className='container text-center'>
            <Card>
                <Card.Header>Thank you for submitting your answers. </Card.Header>
                <Card.Body>
                    <Card.Title>Your Session ended</Card.Title>
                    <Card.Text>
                    Please note that you can't go back or reattempt the test. If you have any queries then reach out to us over a call at 9127067064
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </div>
    );
}

export default Success;