import API from "./apiConfig";

export default {

    ans(ans, user, question) {
        return API.post('/ans', { ans, user, question })
    },
    getAnswer(user, question) {
        return API.post('/getAnswer', { user, question })
    },
    setTime(id) {
        return API.post('/setTime', { id })
    },
    getUser(id) {
        return API.get(`/getUser/${id}`);
    },
    expire(user) {
        return API.post('/expire', { user });
    },
    allanswers() {
        return API.get('/allanswers');
    },

    // login 
    register(formData) {
        return API.post('/register', { formData });
    },

    login(email, password) {
        return API.post('/login', { email, password })
    }




}