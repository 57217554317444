import React, { useEffect, useState } from 'react'
import Navbar from '../components/common/nav'
import Question1 from '../components/home/question1'
import Userinfo from '../components/home/userInfo'
import api from '../API/api'
import AuthUser from '../API/token'
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
    const navigate = useNavigate();

    const { user } = AuthUser();

    const [userInfo, setUserInfo] = useState({
        start_time: '',
        end_time: ''
    });

    useEffect(() => {

        if (user.start_time) {
            window.location.href = './q1';
        }

        getUser();

    }, []);

    const getUser = () => {
        if (user) {
            api.getUser(user.id)
                .then((res) => {
                    console.log(res);
                    setUserInfo(res.data.user)
                    if (res.data.user.start_time) {
                        window.location.href = './q1';
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                })
        }
    }





    const setTime = () => {

        api.setTime(user.id)
            .then((res) => {
                console.log("re", res);
                if (res.data.status == 200) {
                    window.location.href = './q1';

                }
            })
            .catch((err) => {
                console.log(err.response);
            })

    }


    return (
        <>
            <div className=''>
                <div className='mt-4'>
                    <Userinfo />
                </div>

                {userInfo &&
                    <div className='text-center'>
                        <button className='btn btn-info' onClick={setTime}>Start Test</button>
                    </div>
                }
            </div>

        </>
    )
}

export default Homepage;