import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./PublicPages/homepage";
import Dashboard from "./AdminPages/dashboard/dashboard";
import Login from "./AdminPages/form/login";
import Register from "./AdminPages/form/register";
// import Navbar from './components/common/nav';
import Navbar from "./components/common/navbar";

import Question1 from "./components/home/question1";
import Question2 from "./components/home/question2";
import Question3 from "./components/home/question3";
import Success from "./components/home/success";
import Timer from "./components/home/timer";
import Report from "./AdminPages/report";

import Userinfo from "./components/home/userInfo";
import AuthUser from "./API/token";
function App() {
  const { getToken } = AuthUser();
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="mt-4"></div>
      <div className="container">{/* <Userinfo /> */}</div>

      {getToken() && (
        <Routes>
          <Route path="/" element={<Homepage />} />

          <Route path="/q1" element={<Question1 />} />
          <Route path="/q2" element={<Question2 />} />
          <Route path="/q3" element={<Question3 />} />
          <Route path="/success" element={<Success />} />
          <Route path="/timer" element={<Timer />} />

          <Route path="/login" element={<Login />} />
        </Routes>
      )}

      {!getToken() && (
        <Routes>
          <Route path="/register1s" element={<Register />} />
          <Route path="/" element={<Login />} />
          <Route path="/success" element={<Success />} />
          <Route path="/*" element={<Login />} />
          <Route
            path="/report/admin/careers/answers/ptgm"
            element={<Report />}
          />
        </Routes>
      )}

      {/* 
      <Routes>
        <Route path='/admin' element={<Dashboard />} />
      </Routes>



      <Routes>
        <Route path='/admin' element={<Dashboard />}>
        </Route>

      </Routes> */}
    </>
  );
}

export default App;
